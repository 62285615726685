import {
  ActionGroup,
  AlertVariant,
  Button,
  FormGroup,
  PageSection,
  ValidatedOptions,
} from "@patternfly/react-core";

import { ViewHeader } from "../../../../components/view-header/ViewHeader";

import { FormProvider, useForm } from "react-hook-form";

import { FormAccess } from "../../../../components/form/FormAccess";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useServerInfo } from "../../../../context/server-info/ServerInfoProvider";
import { useAdminClient } from "../../../../admin-client";
import { useRealm } from "../../../../context/realm-context/RealmContext";


import { toIntegrationRoute } from "../../../routes/integrationsRoutes";
import { AdminEvents } from "../../../enums/AdminEvents";
import { getAuthorizationHeaders } from "../../../../utils/getAuthorizationHeaders";
import { TextControl, useAlerts, SelectControl, SelectVariant } from "@keycloak/keycloak-ui-shared";

export type CreateWebhookSchema = {
  name: string;
  url: string;
  type: "plain" | "jwt";
  loginEvents: string[];
  adminEvents: string[];
};

export type FormattedWebhookSchema = {
  name: string;
  url: string;
  type: "plain" | "jwt";
  eventsListeners: string[];
  adminEventsListeners: string[];
};

export default function CreateWebhook() {
  const form = useForm<CreateWebhookSchema>({
    defaultValues: {
      name: "",
      url: "",
      type: "plain",
      loginEvents: [],
      adminEvents: [],
    },
  });

  const { enums } = useServerInfo();
  const navigate = useNavigate();
  const { addAlert, addError } = useAlerts();
  const { adminClient } = useAdminClient();

  const eventTypeOptions = (enums?.["eventType"] ?? []).map((item) => ({
    label: item,
    value: item,
  }));

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = form;

  const { realm } = useRealm();

  const { t } = useTranslation();

  const onSubmit = async (data: CreateWebhookSchema) => {
    const realmRep = await adminClient.realms.findOne({ realm });
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/integrations`;
    try {
      const formattedData = {
        uuid: null,
        realmId: realmRep?.id,
        name: data.name,
        url: data.url,
        type: data?.type,

        eventsListeners:
          data.loginEvents && data.loginEvents.length > 0
            ? data.loginEvents
            : [],
            
        adminEventsListeners:
          data.adminEvents && data.adminEvents.length > 0
            ? data.adminEvents
            : [],
      };

      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(formattedData),
        headers: {
          "Content-Type": "application/json",
          ...getAuthorizationHeaders(await adminClient.getAccessToken()),
        },
      });

      navigate(toIntegrationRoute({ realm }));
      if (response.ok) {
        const successMessage = t("point-webhookCreatedSuccessfuly");
        addAlert(successMessage, AlertVariant.success);
      }
    } catch (error) {
      addError(t("point-error"), error);
    }
  };

  return (
    <>
      <ViewHeader titleKey={t("point-createWebhook")} />

      <PageSection variant="light">
        <FormProvider {...form}>
          <FormAccess
            role="manage-identity-providers"
            isHorizontal
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextControl
              name="name"
              control={control}
              label={t("point-webhookName")}
              data-testid="name"
              rules={{ required: {
                value: true,
                message: t("common:required")
              }}} 
              />
            <TextControl
              name="url"
              control={control}
              label={t("point-notificationUrl")}
              data-testid="url"
              rules={{ required: {
                value: true,
                message: t("common:required")
              }}} 
              />
            <SelectControl
              label={t("point-type")}
              id="type"
              name="type"
              controller={{ defaultValue: ""}}

              options={[
                { key: "plain", value: "JSON" },
                { key: "jwt", value: "JWT" },
              ]}
            />
            <SelectControl
              label={t("point-loginEvents")}
              name="loginEvents"
              controller={{ defaultValue: ""}}
              variant={SelectVariant.typeaheadMulti}
              options={eventTypeOptions.map((item) => ({
                key: item.value,
                value: item.label,
              }))}
            />
            <SelectControl
              label={t("point-adminEvents")}
              name="adminEvents"
              controller={{ defaultValue: ""}}
              options={Object.values(AdminEvents).map((item) => ({
                key: item,
                value: item,
              }))}
              variant={SelectVariant.typeaheadMulti}
            />

            <ActionGroup>
              <Button
                isDisabled={!isDirty}
                variant="primary"
                type="submit"
                data-testid="createProvider"
              >
                {t("common:add")}
              </Button>
              <Button
                variant="link"
                data-testid="cancel"
                component={(props) => (
                  <Link {...props} to={toIntegrationRoute({ realm })} />
                )}
              >
                {t("common:cancel")}
              </Button>
            </ActionGroup>
          </FormAccess>
        </FormProvider>
      </PageSection>
    </>
  );
}
