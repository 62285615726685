import {
  Avatar,
  Brand,
  Dropdown,
  DropdownItem,
  Divider,
  MenuToggle,
  DropdownList,
  ToolbarGroup,
} from "@patternfly/react-core";
import { EllipsisVIcon, HelpIcon } from "@patternfly/react-icons";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEnvironment, useHelp } from "@keycloak/keycloak-ui-shared";

import { HelpHeader } from "../../components/help-enabler/HelpHeader";
import { useRealm } from "../../context/realm-context/RealmContext";
import { useWhoAmI } from "../../context/whoami/WhoAmI";
import { toDashboard } from "../../dashboard/routes/Dashboard";
import {environment} from "../../environment";
import { useAdminClient } from "../../admin-client";
import { Toolbar, ToolbarContent, ToolbarItem } from "@patternfly/react-core";
import { Masthead, MastheadBrand, MastheadContent } from "@patternfly/react-core";

import pointLogo from "../assets/point-logo.png";


const ManageAccountDropdownItem = () => {
  const { t } = useTranslation();
  const { keycloak } = useAdminClient();
  return (
    <DropdownItem
      key="manage account"
      id="manage-account"
      onClick={() => keycloak.accountManagement()}
    >
      {t("manageAccount")}
    </DropdownItem>
  );
};

const SignOutDropdownItem = () => {
  const { t } = useTranslation();
  const { keycloak } = useAdminClient();
  return (
    <DropdownItem
      id="sign-out"
      key="sign out"
      onClick={() => keycloak.logout({ redirectUri: "" })}
    >
      {t("signOut")}
    </DropdownItem>
  );
};

const ServerInfoDropdownItem = () => {
  const { realm } = useRealm();
  const { t } = useTranslation();

  return (
    <DropdownItem
      key="server info"
      component={
        ((props: any) => (
          <Link {...props} to={toDashboard({ realm })} />
        )) as React.ComponentType<any>
      }
    >
      {t("realmInfo")}
    </DropdownItem>
  );
};

const HelpDropdownItem = () => {
  const { t } = useTranslation();
  const { enabled, toggleHelp } = useHelp();
  return (
    <DropdownItem icon={<HelpIcon />} onClick={toggleHelp}>
      {enabled ? t("helpEnabled") : t("helpDisabled")}
    </DropdownItem>
  );
};

const kebabDropdownItems = [
  <ManageAccountDropdownItem key="kebab Manage Account" />,
  <ServerInfoDropdownItem key="kebab Server Info" />,
  <HelpDropdownItem key="kebab Help" />,
  <Divider component="li" key="kebab sign out separator" />,
  <SignOutDropdownItem key="kebab Sign out" />,
];

const userDropdownItems = [
  <ManageAccountDropdownItem key="Manage Account" />,
  <ServerInfoDropdownItem key="Server info" />,
  <Divider component="li" key="kebab sign out separator" />,
  <SignOutDropdownItem key="Sign out" />,
];

const KebabDropdown = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element;
    if (!target.closest('#user-dropdown-kebab')) {
    setDropdownOpen(false);
  }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  return (
    <Dropdown
      id="user-dropdown-kebab"
      isPlain
      popperProps={{ position: "right" }}
      toggle={(ref) => (
        <MenuToggle
          ref={ref}
          variant="plain"
          onClick={() => setDropdownOpen(!isDropdownOpen)}
          isExpanded={isDropdownOpen}
        >
          <EllipsisVIcon />
        </MenuToggle>
      )}
      isOpen={isDropdownOpen}
    >
      <DropdownList>{kebabDropdownItems}</DropdownList>
    </Dropdown>
  );
};

const UserDropdown = () => {
  const { whoAmI } = useWhoAmI();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element;
      if (!target.closest('#user-dropdown')) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  
  return (
    <Dropdown
      isPlain
      popperProps={{ position: "right" }}
      id="user-dropdown"
      isOpen={isDropdownOpen}
      toggle={(ref) => (
        <MenuToggle
          ref={ref}
          variant="plain"
          onClick={() => setDropdownOpen(!isDropdownOpen)}
          isExpanded={isDropdownOpen}
        >
          {whoAmI.getDisplayName()}
        </MenuToggle>
      )}
    >
      <DropdownList>{userDropdownItems}</DropdownList>
    </Dropdown>
  );
};

export const Header = () => {
  const { realm } = useRealm();
  const { keycloak } = useAdminClient();
  const { environment } = useEnvironment(); 

const headerTools = () => {
  const picture = keycloak.tokenParsed?.picture;
  return (
    <Toolbar>
      <ToolbarContent>
        <ToolbarItem
          visibility={{
            default: "hidden",
            md: "visible",
          }}
        >
          <HelpHeader />
        </ToolbarItem>
        <ToolbarItem
          visibility={{
            md: "hidden",
          }}
        >
          <KebabDropdown />
        </ToolbarItem>
        <ToolbarItem
          visibility={{
            default: "hidden",
            md: "visible",
          }}
        >
          <UserDropdown />
          <Avatar
            src={picture || environment.resourceUrl + "/img_avatar.svg"}
            alt="Avatar image"
          />
        </ToolbarItem>
      </ToolbarContent>
    </Toolbar>
  );
};

  

  const logoUrl = environment.logoUrl
    ? environment.logoUrl
    : toDashboard({ realm });

  return (
    <Masthead>
    <MastheadBrand>
      <Link to={logoUrl}>
        <Brand
          src={pointLogo}
          id="masthead-logo"
          alt="Logo"
          className="keycloak__pageheader_brand"
        /> 
      </Link>
    </MastheadBrand>
    <MastheadContent style={{ marginLeft: 'auto'}}>{headerTools()}</MastheadContent>
  </Masthead>
  );
};
