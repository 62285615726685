import {
  ActionGroup,
  AlertVariant,
  Button,
  FormGroup,
  PageSection,
} from "@patternfly/react-core";
import { ViewHeader } from "../../../../components/view-header/ViewHeader";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAccess } from "../../../../components/form/FormAccess";
import { getAuthorizationHeaders } from "../../../../utils/getAuthorizationHeaders";
import { useNavigate } from "react-router-dom";
import { useRealm } from "../../../../context/realm-context/RealmContext";
import { toEmailThemeScope } from "../../../routes/emailThemeRoutes";
import { TextControl, useAlerts } from "@keycloak/keycloak-ui-shared";
import { useAdminClient } from "../../../../admin-client";

export type CreateTheme = {
  themeName: string;
};
export default function CreateEmailTheme() {
  const form = useForm<CreateTheme>({
    defaultValues: {
      themeName: "",
    },
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = form;

  const { t } = useTranslation();
  const { addAlert, addError } = useAlerts();
  const navigate = useNavigate();
  const { realm } = useRealm();
  const { adminClient } = useAdminClient();

  const onSubmit = async (data: CreateTheme) => {
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/emails/create/theme/${data.themeName}`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: getAuthorizationHeaders(await adminClient.getAccessToken()),
      });
      addAlert("Success ! New theme created", AlertVariant.success);
      const jsonsData = await response.json();
      navigate(
        toEmailThemeScope({
          realm,
          id: jsonsData.uuid,
        }),
      );
    } catch (error) {
      addError(t("point-error"), error);
    }
  };

  return (
    <>
      <ViewHeader titleKey={t("point-createEmailTheme")} />
      <PageSection variant="light">
        <FormProvider {...form}>
          <FormAccess
            role="manage-identity-providers"
            isHorizontal
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormGroup
              isRequired
              fieldId="theme_name">
              <TextControl 
                name="themeName"
                control={control}
                label="Theme Name"
                data-testid="themeNameInput"
                rules={{ required: true }} 
                />
              <ActionGroup>
                <Button
                  isDisabled={!isDirty}
                  variant="primary"
                  type="submit"
                  data-testid="createProvider"
                >
                  {t("common:add")}
                </Button>
              </ActionGroup>
            </FormGroup>
          </FormAccess>
        </FormProvider>
      </PageSection>
    </>
  );
}
