import { useCallback, useEffect } from "react";

import {
  ActionGroup,
  AlertVariant,
  Button,
  FormGroup,
  PageSection,
  ValidatedOptions,
} from "@patternfly/react-core";

import { ViewHeader } from "../../../../components/view-header/ViewHeader";

import { FormProvider, useForm } from "react-hook-form";

import { FormAccess } from "../../../../components/form/FormAccess";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useAdminClient } from "../../../../admin-client";
import { useRealm } from "../../../../context/realm-context/RealmContext";
import { getAuthorizationHeaders } from "../../../../utils/getAuthorizationHeaders";

import { useAlerts } from "@keycloak/keycloak-ui-shared";

import { CreateLdapSchema } from "../CreateLdap";
import { toLdapRoute } from "../../../routes/ldapRoutes";
import LdapService from "../../../services/ldapService";
import { TextControl } from "@keycloak/keycloak-ui-shared";

export default function EditLdap() {
  const { ldapId } = useParams();
  const form = useForm<CreateLdapSchema>({
    defaultValues: {
      name: "",
      ldapHost: "",
      ldapDomainName: "",
      ldapPort: "",
      ldapBindUsername: "",
      ldapBindPassword: "",
      ldapOrganizationalUnit: "",
    },
  });

  const navigate = useNavigate();
  const { addAlert, addError } = useAlerts();

  const {
    control,
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = form;

  const { realm } = useRealm();

  const { t } = useTranslation();

  const {adminClient} = useAdminClient();

  const onSubmit = async (data: CreateLdapSchema) => {
    if (!ldapId) return;
    const realmRep = await adminClient.realms.findOne({ realm });
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/ldap-connections`;
    try {
      const formattedData = {
        uuid: data.id,
        realmId: realmRep?.id,
        name: data.name,
        ldapHost: data.ldapHost,
        ldapDomainName: data.ldapDomainName,
        ldapPort: data.ldapPort,
        ldapBindUsername: data.ldapBindUsername,
        ldapBindPassword: data.ldapBindPassword,
        ldapOrganizationalUnit: data.ldapOrganizationalUnit,
      };

      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(formattedData),
        headers: {
          "Content-Type": "application/json",
          ...getAuthorizationHeaders(await adminClient.getAccessToken()),
        },
      });

      navigate(toLdapRoute({ realm }));
      if (response.ok) {
        const successMessage = t("point-ldapEditedSuccessfuly");
        addAlert(successMessage, AlertVariant.success);
      }
    } catch (error) {
      addError(t("point-error"), error);
    }
  };

  const getIntegrations = useCallback(async () => {
    if (!ldapId) return;

    const realmRep = await adminClient.realms.findOne({ realm });
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/ldap-connections/${ldapId}/${realmRep?.id}`;
    const ldapResponse = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getAuthorizationHeaders(await adminClient.getAccessToken()),
      },
    });
    const ldapJson = await ldapResponse.json();
    if (ldapJson) {
      reset({
        id: ldapJson.uuid,
        name: ldapJson.name,
        ldapHost: ldapJson.ldapHost,
        ldapDomainName: ldapJson.ldapDomainName,
        ldapPort: ldapJson.ldapPort,
        ldapBindUsername: ldapJson.ldapBindUsername,
        ldapBindPassword: ldapJson.ldapBindPassword,
        ldapOrganizationalUnit: ldapJson.ldapOrganizationalUnit,
      });
    }
  }, [ldapId, realm, reset]);

  const testLdapConnection = async () => {
    const { ldapBindPassword, ldapBindUsername, ldapHost, ldapPort } =
      getValues();
    const ldapConnectionTestResponse = await LdapService.pointLDAPConnection({
      ldapBindPassword,
      ldapBindUsername,
      ldapHost,
      ldapPort,
    });
    if (ldapConnectionTestResponse) {
      addAlert("Connection successful!", AlertVariant.success);
    } else {
      addAlert("Failed to Test Connection", AlertVariant.danger);
    }
  };

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  return (
    <>
      <ViewHeader titleKey={t("point-editLdapConnections")} />

      <PageSection variant="light">
        <FormProvider {...form}>
          <FormAccess
            role="manage-identity-providers"
            isHorizontal
            onSubmit={handleSubmit(onSubmit)}
          >
              <TextControl isDisabled id="id" value={ldapId} name={"id"} label={t("point-ldapId")} />

              <TextControl
                name="name"
                control={control}
                label={t("point-name")}
                data-testid="name"
                rules={{
                  required: {
                    value: true,
                    message: t("required")
                  }
                }}
              />
              <TextControl
                name="ldapHost"
                control={control}
                label={t("point-ldapHost")}
                data-testid="ldapHost"
                rules={{
                  required: {
                    value: true,
                    message: t("required")
                  }
                }}
              />
              <TextControl
                name="ldapPort"
                control={control}
                label={t("point-ldapPort")}
                data-testid="ldapPort"
                rules={{
                  required: {
                    value: true,
                    message: t("required")
                  }
                }}
              />
              <TextControl
                name="ldapBindUsername"
                control={control}
                label={t("point-ldapBindUsername")}
                data-testid="ldapBindUsername"
                rules={{
                  required: {
                    value: true,
                    message: t("required")
                  }
                }}
              />
              <TextControl
                name="ldapBindPassword"
                control={control}
                label={t("point-ldapBindPassword")}
                data-testid="ldapBindPassword"
                rules={{
                  required: {
                    value: true,
                    message: t("required")
                  }
                }}
              />
              <TextControl
                name="ldapDomainName"
                control={control}
                label={t("point-ldapDomainName")}
                data-testid="ldapDomainName"
                rules={{
                  required: {
                    value: true,
                    message: t("required")
                  }
                }}
              />
              <TextControl
                name="ldapOrganizationalUnit"
                control={control}
                label={t("point-ldapOrganizationalUnit")}
                data-testid="ldapOrganizationalUnit"
              />
            <ActionGroup>
              <Button
                variant="primary"
                type="submit"
                data-testid="createProvider"
              >
                {t("common:save")}
              </Button>
              <Button
                variant="link"
                data-testid="cancel"
                component={(props) => (
                  <Link {...props} to={toLdapRoute({ realm })} />
                )}
              >
                {t("common:cancel")}
              </Button>
              <Button
                disabled={isDirty}
                variant="tertiary"
                data-testid="ldapTestConnection"
                onClick={testLdapConnection}
              >
                {t("point-ldapTestConnection")}
              </Button>
            </ActionGroup>
          </FormAccess>
        </FormProvider>
      </PageSection>
    </>
  );
}
