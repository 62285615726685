import { jsx as t, jsxs as b, Fragment as H } from "react/jsx-runtime";
import { AlertGroup as rn, Alert as an, AlertVariant as Me, AlertActionCloseButton as ln, Page as on, Modal as It, ModalVariant as sn, Button as L, TextContent as cn, Text as Pe, TextVariants as dn, Spinner as wt, FormHelperText as Ue, HelperText as He, HelperTextItem as We, Popover as un, Icon as Le, FormGroup as xt, NumberInput as hn, ValidatedOptions as Q, InputGroup as Ce, InputGroupItem as Ne, TextInput as ke, Select as Ie, MenuToggle as de, MenuToggleStatus as je, SelectList as we, SelectOption as Ge, TextInputGroup as Tt, TextInputGroupMain as St, ChipGroup as ze, Chip as Je, TextInputGroupUtilities as At, Switch as pn, TextArea as Et, Title as mn, Card as fn, CardHeader as gn, CardTitle as yn, CardBody as vn, Grid as bn, GridItem as ot, PageSection as Cn, JumpLinks as kn, JumpLinksItem as In, ButtonVariant as ve, Checkbox as wn, Radio as xn, MenuFooter as Tn, Dropdown as Sn, DropdownList as An, DropdownItem as it, Masthead as En, MastheadToggle as Rn, PageToggleButton as Dn, MastheadBrand as On, MastheadContent as Fn, Toolbar as ye, ToolbarContent as Be, ToolbarItem as K, Avatar as Mn, EmptyState as Pn, EmptyStateIcon as st, EmptyStateHeader as Ln, EmptyStateBody as Nn, EmptyStateFooter as Bn, EmptyStateActions as Vn, SearchInput as _n, Divider as qn, Pagination as $n, Bullseye as Kn, Badge as Un } from "@patternfly/react-core";
import { createContext as Rt, useContext as Dt, useRef as Y, useEffect as le, useCallback as ce, useState as x, useMemo as _, forwardRef as Hn, useId as Ot, Fragment as Ft, Children as Mt, Component as Wn, isValidElement as jn } from "react";
import { useTranslation as B } from "react-i18next";
import { NetworkError as Pt } from "@keycloak/keycloak-admin-client";
import Gn from "keycloak-js";
import { ExclamationCircleIcon as zn, HelpIcon as ct, EyeIcon as Jn, EyeSlashIcon as Qn, TimesIcon as Lt, CubeIcon as Yn, PaypalIcon as Xn, InstagramIcon as Zn, BitbucketIcon as er, MicrosoftIcon as tr, TwitterIcon as nr, StackOverflowIcon as rr, OpenshiftIcon as ar, LinkedinIcon as lr, GoogleIcon as or, GitlabIcon as ir, FacebookSquareIcon as sr, GithubIcon as cr, MinusCircleIcon as dr, PlusCircleIcon as Nt, EllipsisVIcon as ur, BarsIcon as hr, SearchIcon as pr, SyncAltIcon as mr } from "@patternfly/react-icons";
import { useFormContext as xe, Controller as oe, useController as Qe, FormProvider as fr, useWatch as gr } from "react-hook-form";
import { get as U, cloneDeep as yr, differenceBy as vr } from "lodash-es";
import me from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as br } from "@patternfly/react-styles";
import { Table as Cr, TableVariant as kr, Thead as Ir, Tr as fe, Th as Oe, Tbody as dt, Td as ae, ExpandableRowContent as wr, ActionsColumn as xr, TableText as Tr } from "@patternfly/react-table";
import './main.css';function Ye(e, n) {
  const r = Rt(n);
  return r.displayName = e, r;
}
const Sr = ["error", "errorMessage"], Fe = "error_description";
function Ar(e) {
  if (typeof e == "string")
    return e;
  if (e instanceof Pt)
    return Dr(e.responseData);
  if (e instanceof Error)
    return e.message;
  throw new Error("Unable to determine error message.");
}
function Er(e) {
  if (!(e instanceof Pt))
    return;
  const n = e.responseData;
  return Rr(n);
}
function Rr(e) {
  if (typeof e == "object" && e !== null && Fe in e && typeof e[Fe] == "string")
    return e[Fe];
}
function Dr(e) {
  if (!(typeof e != "object" || e === null))
    for (const n of Sr) {
      const r = e[n];
      if (typeof r == "string")
        return r;
    }
}
const Or = () => Math.floor(Math.random() * 1e3);
function Fr(e) {
  return e != null;
}
function Xe(e) {
  const n = Dt(e);
  if (Fr(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function Mr() {
  const e = Y(!1), n = Y(/* @__PURE__ */ new Set());
  le(() => (e.current = !1, () => {
    e.current = !0, r();
  }), []);
  function r() {
    n.current.forEach((a) => clearTimeout(a)), n.current.clear();
  }
  return ce((a, l) => {
    if (e.current)
      throw new Error("Can't schedule a timeout on an unmounted component.");
    const o = Number(setTimeout(i, l));
    n.current.add(o);
    function i() {
      n.current.delete(o), a();
    }
    return function() {
      clearTimeout(o), n.current.delete(o);
    };
  }, []);
}
function Pr({ alerts: e, onCloseAlert: n }) {
  return /* @__PURE__ */ t(
    rn,
    {
      "data-testid": "global-alerts",
      isToast: !0,
      style: { whiteSpace: "pre-wrap" },
      children: e.map(({ id: r, variant: a, message: l, description: o }, i) => /* @__PURE__ */ t(
        an,
        {
          "data-testid": i === 0 ? "last-alert" : void 0,
          isLiveRegion: !0,
          variant: Me[a],
          component: "p",
          variantLabel: "",
          title: l,
          actionClose: /* @__PURE__ */ t(
            ln,
            {
              title: l,
              onClose: () => n(r)
            }
          ),
          children: o && /* @__PURE__ */ t("p", { children: o })
        },
        r
      ))
    }
  );
}
const Lr = 8e3, Bt = Ye(
  "AlertContext",
  void 0
), Ka = () => Xe(Bt), Nr = ({ children: e }) => {
  const { t: n } = B(), r = Mr(), [a, l] = x([]), o = (c) => l((h) => h.filter((d) => d.id !== c)), i = ce(
    (c, h = Me.success, d) => {
      const f = {
        id: Or(),
        message: c,
        variant: h,
        description: d
      };
      l((m) => [f, ...m]), r(() => o(f.id), Lr);
    },
    [r]
  ), s = ce(
    (c, h) => {
      const d = n(c, { error: Ar(h) }), f = Er(h);
      i(d, Me.danger, f);
    },
    [i, n]
  ), u = _(() => ({ addAlert: i, addError: s }), [i, s]);
  return /* @__PURE__ */ b(Bt.Provider, { value: u, children: [
    /* @__PURE__ */ t(Pr, { alerts: a, onCloseAlert: o }),
    e
  ] });
}, Br = (e) => {
  const { t: n } = B(), r = e.error, a = Vr(r);
  function l() {
    location.href = location.origin + location.pathname;
  }
  return /* @__PURE__ */ t(on, { children: /* @__PURE__ */ t(
    It,
    {
      variant: sn.small,
      title: n("somethingWentWrong"),
      titleIconVariant: "danger",
      showClose: !1,
      isOpen: !0,
      actions: [
        /* @__PURE__ */ t(L, { variant: "primary", onClick: l, children: n("tryAgain") }, "tryAgain")
      ],
      children: /* @__PURE__ */ b(cn, { children: [
        /* @__PURE__ */ t(Pe, { children: n("somethingWentWrongDescription") }),
        a && /* @__PURE__ */ t(Pe, { component: dn.small, children: a })
      ] })
    }
  ) });
};
function Vr(e) {
  return typeof e == "string" ? e : e instanceof Error ? e.message : null;
}
function _r(e, n, r) {
  const [a, l] = x(
    () => e.getItem(n) ?? r
  ), o = ce((i) => {
    l(i), e.setItem(n, i);
  }, []);
  return le(() => {
    l(e.getItem(n) ?? r), window.addEventListener("storage", i);
    function i(s) {
      s.storageArea === e && (s.key === null || s.key === n) && l(s.newValue ?? r);
    }
    return () => window.removeEventListener("storage", i);
  }, [e, n]), [a, o];
}
function Vt(e, n, r) {
  const a = _(
    () => JSON.stringify(r),
    [r]
  ), [l, o] = _r(
    e,
    n,
    a
  ), i = _(() => JSON.parse(l), [l]), s = ce(
    (u) => o(JSON.stringify(u)),
    []
  );
  return [i, s];
}
const _t = Ye(
  "HelpContext",
  void 0
), qr = () => Xe(_t), $r = ({ children: e }) => {
  const [n, r] = Vt(localStorage, "helpEnabled", !0);
  function a() {
    r(!n);
  }
  return /* @__PURE__ */ t(_t.Provider, { value: { enabled: n, toggleHelp: a }, children: e });
}, Kr = () => Rt(void 0);
let Ve;
const Ua = () => {
  const e = Dt(Ve);
  if (!e)
    throw Error(
      "no environment provider in the hierarchy make sure to add the provider"
    );
  return e;
}, Ha = ({
  environment: e,
  children: n
}) => {
  Ve = Kr();
  const r = Y(!1), [a, l] = x(!1), [o, i] = x(), s = _(() => {
    const u = new Gn({
      url: e.serverBaseUrl,
      realm: e.realm,
      clientId: e.clientId
    });
    return u.onAuthLogout = () => u.login(), u;
  }, [e]);
  return le(() => {
    if (r.current)
      return;
    s.init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      responseMode: "query"
    }).then(() => l(!0)).catch((c) => i(c)), r.current = !0;
  }, [s]), o ? /* @__PURE__ */ t(Br, { error: o }) : a ? /* @__PURE__ */ t(Ve.Provider, { value: { environment: e, keycloak: s }, children: /* @__PURE__ */ t(Nr, { children: /* @__PURE__ */ t($r, { children: n }) }) }) : /* @__PURE__ */ t(wt, {});
};
function Wa() {
  const n = document.getElementById("environment")?.textContent;
  if (typeof n != "string")
    throw new Error("Environment variables not found in the document.");
  try {
    return JSON.parse(n);
  } catch {
    throw new Error("Unable to parse environment variables as JSON.");
  }
}
const ja = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: r,
  buttonTitle: a,
  isDisabled: l,
  buttonVariant: o,
  buttonTestRole: i,
  onContinue: s,
  component: u = L,
  children: c,
  ...h
}) => {
  const [d, f] = x(!1);
  return /* @__PURE__ */ b(H, { children: [
    /* @__PURE__ */ t(
      u,
      {
        variant: o,
        onClick: () => f(!0),
        isDisabled: l,
        "data-testrole": i,
        children: a
      }
    ),
    /* @__PURE__ */ t(
      It,
      {
        variant: "small",
        ...h,
        title: e,
        isOpen: d,
        onClose: () => f(!1),
        actions: [
          /* @__PURE__ */ t(
            L,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                f(!1), s();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ t(
            L,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => f(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: c
      }
    )
  ] });
}, qt = ({ message: e, ...n }) => /* @__PURE__ */ t(Ue, { ...n, children: /* @__PURE__ */ t(He, { children: /* @__PURE__ */ t(We, { icon: /* @__PURE__ */ t(zn, {}), variant: "error", children: e }) }) }), $t = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: r = !0,
  unWrap: a = !1
}) => {
  const { enabled: l } = qr();
  return l ? /* @__PURE__ */ t(un, { bodyContent: e, children: /* @__PURE__ */ b(H, { children: [
    !a && /* @__PURE__ */ t(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (o) => o.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ t(Le, { isInline: r, children: /* @__PURE__ */ t(ct, {}) })
      }
    ),
    a && /* @__PURE__ */ t(Le, { isInline: r, children: /* @__PURE__ */ t(ct, {}) })
  ] }) }) : null;
};
function Ze(e) {
  if (!(typeof e > "u" || e instanceof RegExp))
    return typeof e == "object" ? e.value : e;
}
const ne = ({
  name: e,
  label: n,
  labelIcon: r,
  error: a,
  children: l,
  ...o
}) => /* @__PURE__ */ b(
  xt,
  {
    label: n || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ t($t, { helpText: r, fieldLabelId: e }) : void 0,
    ...o,
    children: [
      l,
      a && /* @__PURE__ */ t(qt, { "data-testid": `${e}-helper`, message: a.message })
    ]
  }
), Ga = ({
  name: e,
  label: n,
  controller: r,
  labelIcon: a,
  ...l
}) => {
  const {
    control: o,
    formState: { errors: i }
  } = xe();
  return /* @__PURE__ */ t(
    ne,
    {
      name: e,
      label: n,
      isRequired: r.rules?.required === !0,
      error: i[e],
      labelIcon: a,
      children: /* @__PURE__ */ t(
        oe,
        {
          ...r,
          name: e,
          control: o,
          render: ({ field: s }) => {
            const u = !!r.rules?.required, c = Ze(r.rules?.min), h = s.value ?? r.defaultValue, d = (f) => s.onChange(
              c !== void 0 ? Math.max(f, Number(c)) : f
            );
            return /* @__PURE__ */ t(
              hn,
              {
                ...l,
                id: e,
                value: h,
                validated: i[e] ? Q.error : Q.default,
                required: u,
                min: Number(c),
                max: Number(r.rules?.max),
                onPlus: () => d(h + 1),
                onMinus: () => d(h - 1),
                onChange: (f) => {
                  const m = Number(f.currentTarget.value);
                  d(isNaN(m) ? r.defaultValue : m);
                }
              }
            );
          }
        }
      )
    }
  );
}, Ur = ({
  hasReveal: e = !0,
  innerRef: n,
  ...r
}) => {
  const { t: a } = B(), [l, o] = x(!0);
  return /* @__PURE__ */ b(Ce, { children: [
    /* @__PURE__ */ t(Ne, { isFill: !0, children: /* @__PURE__ */ t(
      ke,
      {
        ...r,
        type: l ? "password" : "text",
        ref: n
      }
    ) }),
    e && /* @__PURE__ */ t(
      L,
      {
        variant: "control",
        "aria-label": a("showPassword"),
        onClick: () => o(!l),
        children: l ? /* @__PURE__ */ t(Jn, {}) : /* @__PURE__ */ t(Qn, {})
      }
    )
  ] });
}, Kt = Hn(
  (e, n) => /* @__PURE__ */ t(Ur, { ...e, innerRef: n })
);
Kt.displayName = "PasswordInput";
const za = (e) => {
  const { labelIcon: n, ...r } = e, a = !!e.rules?.required, l = e.defaultValue ?? "", { field: o, fieldState: i } = Qe({
    ...e,
    defaultValue: l
  });
  return /* @__PURE__ */ b(
    ne,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: a,
      error: i.error,
      children: [
        /* @__PURE__ */ t(
          Kt,
          {
            isRequired: a,
            id: e.name,
            "data-testid": e.name,
            validated: i.error ? Q.error : Q.default,
            isDisabled: e.isDisabled,
            ...r,
            ...o
          }
        ),
        e.helperText && /* @__PURE__ */ t(Ue, { children: /* @__PURE__ */ t(He, { children: /* @__PURE__ */ t(We, { children: e.helperText }) }) })
      ]
    }
  );
}, Hr = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: o,
  ...i
}) => {
  const {
    control: s,
    formState: { errors: u }
  } = xe(), [c, h] = x(!1), d = Ze(l.rules?.required) === !0;
  return /* @__PURE__ */ t(
    ne,
    {
      name: n,
      label: r,
      isRequired: d,
      error: U(u, n),
      labelIcon: o,
      children: /* @__PURE__ */ t(
        oe,
        {
          ...l,
          name: n,
          control: s,
          render: ({ field: { onChange: f, value: m } }) => /* @__PURE__ */ t(
            Ie,
            {
              ...i,
              onClick: () => h(!c),
              onOpenChange: () => h(!1),
              selected: se(a) ? a.filter(
                (v) => Array.isArray(m) ? m.includes(v.key) : m === v.key
              ).map((v) => v.value) : m,
              toggle: (v) => /* @__PURE__ */ t(
                de,
                {
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  ref: v,
                  onClick: () => h(!c),
                  isExpanded: c,
                  isFullWidth: !0,
                  status: U(u, n) ? je.danger : void 0,
                  "aria-label": "toggle",
                  children: se(a) ? a.find(
                    (T) => T.key === (Array.isArray(m) ? m[0] : m)
                  )?.value : m
                }
              ),
              onSelect: (v, T) => {
                const g = T?.toString();
                f(Array.isArray(m) ? [g] : g), h(!1);
              },
              isOpen: c,
              children: /* @__PURE__ */ t(we, { children: a.map((v) => /* @__PURE__ */ t(Ge, { value: ee(v), children: et(v) ? v : v.value }, ee(v))) })
            }
          )
        }
      )
    }
  );
}, ge = (e) => et(e) ? e : e.value, Wr = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: o,
  placeholderText: i,
  onFilter: s,
  variant: u,
  ...c
}) => {
  const {
    control: h,
    formState: { errors: d }
  } = xe(), [f, m] = x(!1), [v, T] = x(""), [g, p] = x(0), C = Y(), w = Ze(l.rules?.required) === !0, k = a.filter(
    (y) => ge(y).toLowerCase().startsWith(v.toLowerCase())
  ), A = _(
    () => k.map((y, S) => /* @__PURE__ */ t(
      Ge,
      {
        value: ee(y),
        isFocused: g === S,
        children: ge(y)
      },
      ee(y)
    )),
    [g, k]
  ), V = (y, S) => {
    const D = k[g];
    switch (m(!0), y.key) {
      case "Enter": {
        y.preventDefault(), u !== re.typeaheadMulti ? T(ge(D)) : T(""), S.onChange(
          Array.isArray(S.value) ? [...S.value, ee(D)] : ee(D)
        ), m(!1), p(0);
        break;
      }
      case "Tab":
      case "Escape": {
        m(!1), S.onChange(void 0);
        break;
      }
      case "Backspace": {
        u === re.typeahead && S.onChange("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        y.preventDefault();
        let F = 0;
        y.key === "ArrowUp" && (g === 0 ? F = a.length - 1 : F = g - 1), y.key === "ArrowDown" && (g === a.length - 1 ? F = 0 : F = g + 1), p(F);
        break;
      }
    }
  };
  return /* @__PURE__ */ t(
    ne,
    {
      name: n,
      label: r,
      isRequired: w,
      error: U(d, n),
      labelIcon: o,
      children: /* @__PURE__ */ t(
        oe,
        {
          ...l,
          name: n,
          control: h,
          render: ({ field: y }) => /* @__PURE__ */ t(
            Ie,
            {
              ...c,
              onClick: () => m(!f),
              onOpenChange: () => m(!1),
              selected: se(a) ? a.filter(
                (S) => Array.isArray(y.value) ? y.value.includes(S.key) : y.value === S.key
              ).map((S) => S.value) : y.value,
              toggle: (S) => /* @__PURE__ */ t(
                de,
                {
                  ref: S,
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  variant: "typeahead",
                  onClick: () => m(!f),
                  isExpanded: f,
                  isFullWidth: !0,
                  status: U(d, n) ? je.danger : void 0,
                  children: /* @__PURE__ */ b(Tt, { isPlain: !0, children: [
                    /* @__PURE__ */ t(
                      St,
                      {
                        placeholder: i,
                        value: u === re.typeahead && y.value ? se(a) ? a.find(
                          (D) => D.key === (Array.isArray(y.value) ? y.value[0] : y.value)
                        )?.value : y.value : v,
                        onClick: () => m(!f),
                        onChange: (D, F) => {
                          T(F), s?.(F);
                        },
                        onKeyDown: (D) => V(D, y),
                        autoComplete: "off",
                        innerRef: C,
                        role: "combobox",
                        isExpanded: f,
                        "aria-controls": "select-typeahead-listbox",
                        children: u === re.typeaheadMulti && Array.isArray(y.value) && /* @__PURE__ */ t(ze, { "aria-label": "Current selections", children: y.value.map(
                          (D, F) => /* @__PURE__ */ t(
                            Je,
                            {
                              onClick: (E) => {
                                E.stopPropagation(), y.onChange(
                                  y.value.filter(
                                    (M) => M !== ee(D)
                                  )
                                );
                              },
                              children: se(a) ? a.find((E) => D === E.key)?.value : ge(D)
                            },
                            F
                          )
                        ) })
                      }
                    ),
                    /* @__PURE__ */ t(At, { children: (!!v || y.value) && /* @__PURE__ */ t(
                      L,
                      {
                        variant: "plain",
                        onClick: () => {
                          T(""), y.onChange(""), C?.current?.focus();
                        },
                        "aria-label": "Clear input value",
                        children: /* @__PURE__ */ t(Lt, { "aria-hidden": !0 })
                      }
                    ) })
                  ] })
                }
              ),
              onSelect: (S, D) => {
                S?.stopPropagation();
                const F = D?.toString();
                u === re.typeaheadMulti && Array.isArray(y.value) ? y.value.includes(F) ? y.onChange(
                  y.value.filter((E) => E !== F)
                ) : y.onChange([...y.value, F]) : (y.onChange(Array.isArray(y.value) ? [F] : F), m(!1));
              },
              isOpen: f,
              children: /* @__PURE__ */ t(we, { children: A })
            }
          )
        }
      )
    }
  );
};
var re = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(re || {});
const se = (e) => typeof e[0] != "string", et = (e) => typeof e == "string", ee = (e) => et(e) ? e : e.key, jr = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(Hr, { ...n }) : /* @__PURE__ */ t(Wr, { ...n, variant: e }), Ja = ({
  labelOn: e,
  stringify: n,
  defaultValue: r,
  labelIcon: a,
  ...l
}) => {
  const i = r ?? (n ? "false" : !1), { control: s } = xe();
  return /* @__PURE__ */ t(
    ne,
    {
      hasNoPaddingTop: !0,
      name: l.name,
      isRequired: l.rules?.required === !0,
      label: l.label,
      labelIcon: a,
      children: /* @__PURE__ */ t(
        oe,
        {
          control: s,
          name: l.name,
          defaultValue: i,
          render: ({ field: { onChange: u, value: c } }) => /* @__PURE__ */ t(
            pn,
            {
              ...l,
              id: l.name,
              "data-testid": l.name,
              label: e,
              isChecked: n ? c === "true" : c,
              onChange: (h, d) => {
                const f = n ? d.toString() : d;
                l.onChange?.(h, d), u(f);
              }
            }
          )
        }
      )
    }
  );
}, Qa = (e) => {
  const n = !!e.rules?.required, r = e.defaultValue ?? "", { field: a, fieldState: l } = Qe({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ t(
    ne,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: l.error,
      children: /* @__PURE__ */ t(
        Et,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: l.error ? Q.error : Q.default,
          isDisabled: e.isDisabled,
          ...a
        }
      )
    }
  );
}, Ya = (e) => {
  const { labelIcon: n, helperText: r, ...a } = e, l = !!e.rules?.required, o = e.defaultValue ?? "", { field: i, fieldState: s } = Qe({
    ...e,
    defaultValue: o
  });
  return /* @__PURE__ */ b(
    ne,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: s.error,
      children: [
        /* @__PURE__ */ t(
          ke,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e["data-testid"] || e.name,
            validated: s.error ? Q.error : Q.default,
            isDisabled: e.isDisabled,
            ...a,
            ...i
          }
        ),
        r && /* @__PURE__ */ t(Ue, { children: /* @__PURE__ */ t(He, { children: /* @__PURE__ */ t(We, { children: r }) }) })
      ]
    }
  );
}, Gr = Et, Xa = ({ icon: e }) => {
  const n = zr(e);
  return /* @__PURE__ */ t(Le, { size: "lg", children: /* @__PURE__ */ t(n, { alt: e }) });
};
function zr(e) {
  switch (e) {
    case "github":
      return cr;
    case "facebook":
      return sr;
    case "gitlab":
      return ir;
    case "google":
      return or;
    case "linkedin":
    case "linkedin-openid-connect":
      return lr;
    case "openshift-v3":
    case "openshift-v4":
      return ar;
    case "stackoverflow":
      return rr;
    case "twitter":
      return nr;
    case "microsoft":
      return tr;
    case "bitbucket":
      return er;
    case "instagram":
      return Zn;
    case "paypal":
      return Xn;
    default:
      return Yn;
  }
}
const Jr = "_title_180i0_2", Qr = {
  title: Jr
}, Ut = ({
  id: e,
  title: n,
  headingLevel: r = "h1",
  size: a = "xl",
  ...l
}) => /* @__PURE__ */ t(
  mn,
  {
    headingLevel: r,
    size: a,
    className: Qr.title,
    id: e,
    tabIndex: 0,
    ...l,
    children: n
  }
), Yr = ({
  title: e,
  children: n,
  scrollId: r,
  className: a
}) => {
  const l = Ot();
  return /* @__PURE__ */ b(fn, { id: l, className: a, isFlat: !0, children: [
    /* @__PURE__ */ t(gn, { className: "kc-form-panel__header", children: /* @__PURE__ */ t(yn, { tabIndex: 0, children: /* @__PURE__ */ t(Ut, { id: r, title: e }) }) }),
    /* @__PURE__ */ t(vn, { className: "kc-form-panel__body", children: n })
  ] });
}, Xr = (e) => {
  const { title: n, children: r, scrollId: a, ...l } = e;
  return /* @__PURE__ */ t("section", { ...l, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ b(H, { children: [
    /* @__PURE__ */ t(Ut, { id: a, title: n }),
    r
  ] }) });
}, Zr = "_panel_1cdve_1", ea = "_sticky_1cdve_5", ut = {
  panel: Zr,
  sticky: ea
}, ta = "kc-main-content-page-container", ht = (e) => e.replace(/\s+/g, "-"), na = ({
  label: e,
  sections: n,
  borders: r = !1,
  ...a
}) => {
  const l = _(
    () => n.filter(({ isHidden: o }) => !o),
    [n]
  );
  return /* @__PURE__ */ b(bn, { hasGutter: !0, ...a, children: [
    /* @__PURE__ */ t(ot, { md: 8, sm: 12, children: l.map(({ title: o, panel: i }) => {
      const s = ht(o.toLowerCase());
      return /* @__PURE__ */ t(Ft, { children: r ? /* @__PURE__ */ t(
        Yr,
        {
          scrollId: s,
          title: o,
          className: ut.panel,
          children: i
        }
      ) : /* @__PURE__ */ t(Xr, { scrollId: s, title: o, children: i }) }, o);
    }) }),
    /* @__PURE__ */ t(ot, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ t(Cn, { className: ut.sticky, children: /* @__PURE__ */ t(
      kn,
      {
        isVertical: !0,
        scrollableSelector: `#${ta}`,
        label: e,
        offset: 100,
        children: l.map(({ title: o }) => {
          const i = ht(o.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ t(
              In,
              {
                href: `#${i}`,
                "data-testid": `jump-link-${i}`,
                children: o
              },
              o
            )
          );
        })
      }
    ) }) })
  ] });
}, ra = (e, n, r) => (e.isValid || r) && (e.isDirty || n) && !e.isLoading && !e.isValidating && !e.isSubmitting, Za = ({
  formState: e,
  isDisabled: n = !1,
  allowInvalid: r = !1,
  allowNonDirty: a = !1,
  children: l,
  ...o
}) => /* @__PURE__ */ t(
  L,
  {
    variant: "primary",
    isDisabled: e && !ra(e, a, r) || n,
    ...o,
    type: "submit",
    children: l
  }
), aa = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, la = ({
  t: e,
  form: n,
  supportedLocales: r,
  currentLocale: a
}) => {
  const l = _(
    () => r.map((o) => ({
      key: o,
      value: e(`locale_${o}`, aa(o) ?? o)
    })).sort((o, i) => o.value.localeCompare(i.value, a)),
    [r, a, e]
  );
  return l.length ? /* @__PURE__ */ t(fr, { ...n, children: /* @__PURE__ */ t(
    jr,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: l,
      variant: l.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, _e = (e) => e?.includes("${"), qe = (e) => e.substring(2, e.length - 1), te = (e, n, r, a) => {
  const l = n || r, o = _e(l) ? qe(l) : l, i = a ? `${a}.${o}` : o;
  return e(i || "");
}, $e = (e, n) => te(e, n.displayName, n.name), oa = ["username", "firstName", "lastName", "email"], Ht = (e) => e && oa.includes(e), X = (e) => `${Ht(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, el = (e) => e.replaceAll(".", "🍺"), tl = (e) => e.replaceAll("🍺", ".");
function nl(e, n, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((a) => {
    const l = Object.assign(
      {},
      a.params?.map((o) => _e(o.toString()) ? r(qe(o)) : o)
    );
    n(X(a.field), {
      message: r(
        _e(a.errorMessage) ? qe(a.errorMessage) : a.errorMessage,
        {
          ...l,
          defaultValue: a.errorMessage || a.field
        }
      ),
      type: "server"
    });
  });
}
function Te({
  required: e
}) {
  return e;
}
function rl(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return pt(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(pt);
}
function pt(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const ue = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  children: l
}) => {
  const o = te(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: i }
  } = n, s = a?.(r), u = U(i, X(r.name));
  return /* @__PURE__ */ b(
    xt,
    {
      label: $e(e, r) || "",
      fieldId: r.name,
      isRequired: Te(r),
      labelIcon: o ? /* @__PURE__ */ t($t, { helpText: o, fieldLabelId: r.name }) : void 0,
      children: [
        s ? /* @__PURE__ */ b(Ce, { children: [
          l,
          s
        ] }) : l,
        u && /* @__PURE__ */ t(
          qt,
          {
            "data-testid": `${r.name}-helper`,
            message: u.message
          }
        )
      ]
    },
    r.name
  );
}, ia = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  ...l
}) => /* @__PURE__ */ t(ue, { t: e, form: n, attribute: r, renderer: a, children: /* @__PURE__ */ t(
  sa,
  {
    t: e,
    form: n,
    "aria-label": $e(e, r),
    name: X(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: $e(e, r)
    }),
    ...l
  }
) }), sa = ({
  t: e,
  name: n,
  inputType: r,
  form: a,
  addButtonLabel: l,
  isDisabled: o = !1,
  defaultValue: i,
  id: s,
  ...u
}) => {
  const { register: c, setValue: h, control: d } = a, f = gr({
    name: n,
    control: d,
    defaultValue: i || ""
  }), m = _(() => Array.isArray(f) && f.length !== 0 ? f : i || [""], [f]), v = (w) => {
    p([...m.slice(0, w), ...m.slice(w + 1)]);
  }, T = () => {
    p([...m, ""]);
  }, g = (w, k) => {
    p([...m.slice(0, w), k, ...m.slice(w + 1)]);
  }, p = (w) => {
    const k = w.flatMap((A) => A);
    h(n, k, {
      shouldDirty: !0
    });
  }, C = r.startsWith("html") ? r.substring(6) : "text";
  return le(() => {
    c(n);
  }, [c]), /* @__PURE__ */ t("div", { id: s, children: m.map((w, k) => /* @__PURE__ */ b(Ft, { children: [
    /* @__PURE__ */ b(Ce, { children: [
      /* @__PURE__ */ t(Ne, { isFill: !0, children: /* @__PURE__ */ t(
        ke,
        {
          "data-testid": n + k,
          onChange: (A, V) => g(k, V),
          name: `${n}.${k}.value`,
          value: w,
          isDisabled: o,
          type: C,
          ...u
        }
      ) }),
      /* @__PURE__ */ t(Ne, { children: /* @__PURE__ */ t(
        L,
        {
          "data-testid": "remove" + k,
          variant: ve.link,
          onClick: () => v(k),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: m.length === 1 || o,
          children: /* @__PURE__ */ t(dr, {})
        }
      ) })
    ] }),
    k === m.length - 1 && /* @__PURE__ */ b(
      L,
      {
        variant: ve.link,
        onClick: T,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !w || o,
        children: [
          /* @__PURE__ */ t(Nt, {}),
          " ",
          e(l || "add")
        ]
      }
    )
  ] }, k)) });
}, mt = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = Te(a), o = r.startsWith("multiselect"), i = o ? wn : xn, s = a.validators?.options?.options || [], u = a.annotations?.inputOptionLabels || {}, c = a.annotations?.inputOptionLabelsI18nPrefix;
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    oe,
    {
      name: X(a.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: h }) => /* @__PURE__ */ t(H, { children: s.map((d) => /* @__PURE__ */ t(
        i,
        {
          id: d,
          "data-testid": d,
          label: te(e.t, u[d], d, c),
          value: d,
          isChecked: h.value.includes(d),
          onChange: () => {
            o ? h.value.includes(d) ? h.onChange(
              h.value.filter((f) => f !== d)
            ) : h.onChange([...h.value, d]) : h.onChange([d]);
          },
          readOnly: a.readOnly,
          isRequired: l
        },
        d
      )) })
    }
  ) });
}, ca = ({
  toggleId: e,
  onToggle: n,
  onSelect: r,
  selections: a,
  isOpen: l,
  menuAppendTo: o,
  direction: i,
  width: s,
  maxHeight: u,
  toggleIcon: c,
  className: h,
  isDisabled: d,
  children: f,
  ...m
}) => {
  const [v, T] = x(!1), g = Y(), p = () => {
    T(!v), n(!v);
  }, C = () => o === "parent" && g.current?.parentElement || "inline", w = Mt.toArray(
    f
  );
  return /* @__PURE__ */ t(
    Ie,
    {
      ref: g,
      maxMenuHeight: be(u),
      isScrollable: !0,
      popperProps: {
        appendTo: C(),
        direction: i,
        width: be(s)
      },
      ...m,
      onClick: p,
      onOpenChange: (k) => {
        k !== v && p();
      },
      selected: a,
      onSelect: (k, A) => {
        r?.(A || ""), p();
      },
      toggle: (k) => /* @__PURE__ */ t(
        de,
        {
          id: e,
          ref: k,
          className: h,
          onClick: p,
          isExpanded: l,
          "aria-label": m["aria-label"],
          icon: c,
          isDisabled: d,
          isFullWidth: !0,
          children: w.find((A) => A.props.value === a)?.props.children || a || m["aria-label"]
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(we, { children: f })
    }
  );
}, da = ({
  toggleId: e,
  onSelect: n,
  onToggle: r,
  onFilter: a,
  variant: l,
  validated: o,
  placeholderText: i,
  maxHeight: s,
  width: u,
  toggleIcon: c,
  direction: h,
  selections: d,
  typeAheadAriaLabel: f,
  chipGroupComponent: m,
  chipGroupProps: v,
  footer: T,
  isDisabled: g,
  children: p,
  ...C
}) => {
  const [w, k] = x(""), [A, V] = x(0), y = Y(), S = Mt.toArray(
    p
  ), D = () => {
    r?.(!C.isOpen);
  }, F = (E) => {
    const M = S[A];
    switch (r?.(!0), E.key) {
      case "Enter": {
        E.preventDefault(), l !== J.typeaheadMulti ? k(M.props.value) : k(""), n?.(M.props.value), r?.(!1), V(0);
        break;
      }
      case "Escape": {
        r?.(!1);
        break;
      }
      case "Backspace": {
        l === J.typeahead && n?.("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        E.preventDefault();
        let N = 0;
        E.key === "ArrowUp" && (A === 0 ? N = S.length - 1 : N = A - 1), E.key === "ArrowDown" && (A === S.length - 1 ? N = 0 : N = A + 1), V(N);
        break;
      }
    }
  };
  return /* @__PURE__ */ b(
    Ie,
    {
      ...C,
      onClick: D,
      onOpenChange: (E) => r?.(E),
      onSelect: (E, M) => n?.(M || ""),
      maxMenuHeight: be(s),
      popperProps: { direction: h, width: be(u) },
      toggle: (E) => /* @__PURE__ */ t(
        de,
        {
          ref: E,
          id: e,
          variant: "typeahead",
          onClick: () => r?.(!0),
          icon: c,
          isDisabled: g,
          isExpanded: C.isOpen,
          isFullWidth: !0,
          status: o === "error" ? je.danger : void 0,
          children: /* @__PURE__ */ b(Tt, { isPlain: !0, children: [
            /* @__PURE__ */ t(
              St,
              {
                placeholder: i,
                value: l === J.typeahead && d ? d : w,
                onClick: D,
                onChange: (M, N) => {
                  k(N), a?.(N);
                },
                onKeyDown: (M) => F(M),
                autoComplete: "off",
                innerRef: y,
                role: "combobox",
                isExpanded: C.isOpen,
                "aria-controls": "select-typeahead-listbox",
                "aria-label": f,
                children: l === J.typeaheadMulti && Array.isArray(d) && (m || /* @__PURE__ */ t(ze, { ...v, children: d.map((M, N) => /* @__PURE__ */ t(
                  Je,
                  {
                    onClick: (Se) => {
                      Se.stopPropagation(), n?.(M);
                    },
                    children: M
                  },
                  N
                )) }))
              }
            ),
            /* @__PURE__ */ t(At, { children: !!w && /* @__PURE__ */ t(
              L,
              {
                variant: "plain",
                onClick: () => {
                  n?.(""), k(""), a?.(""), y?.current?.focus();
                },
                "aria-label": "Clear input value",
                children: /* @__PURE__ */ t(Lt, { "aria-hidden": !0 })
              }
            ) })
          ] })
        }
      ),
      children: [
        /* @__PURE__ */ t(we, { children: p }),
        T && /* @__PURE__ */ t(Tn, { children: T })
      ]
    }
  );
};
var J = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(J || {});
const be = (e) => typeof e == "number" ? e + "px" : e, ua = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(ca, { ...n }) : /* @__PURE__ */ t(da, { ...n, variant: e }), ft = (e) => {
  const { t: n, form: r, inputType: a, attribute: l } = e, [o, i] = x(!1), [s, u] = x(""), c = a === "multiselect", h = (g, p) => {
    c ? p.value.includes(g) ? p.onChange(p.value.filter((C) => C !== g)) : Array.isArray(p.value) ? p.onChange([...p.value, g]) : p.onChange([g]) : p.onChange(g === p.value ? "" : g);
  }, d = l.validators?.options?.options || [], f = l.annotations?.inputOptionLabels || {}, m = l.annotations?.inputOptionLabelsI18nPrefix, v = (g) => te(e.t, f[g], g, m), T = (g) => d.filter(
    (p) => v(p).toLowerCase().includes(s.toLowerCase())
  ).map((p) => /* @__PURE__ */ t(
    Ge,
    {
      selected: g === p,
      value: p,
      children: v(p)
    },
    p
  ));
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    oe,
    {
      name: X(l.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: g }) => /* @__PURE__ */ t(
        ua,
        {
          toggleId: l.name,
          onToggle: (p) => i(p),
          onClear: () => h("", g),
          onSelect: (p) => {
            const C = p.toString();
            h(C, g), Array.isArray(g.value) || i(!1);
          },
          selections: c && Array.isArray(g.value) ? g.value : v(g.value),
          variant: c ? J.typeaheadMulti : d.length >= 10 ? J.typeahead : J.single,
          "aria-label": n("selectOne"),
          isOpen: o,
          isDisabled: l.readOnly,
          onFilter: (p) => (u(p), T(g.value)),
          children: T(g.value)
        }
      )
    }
  ) });
}, ha = (e) => {
  const { form: n, attribute: r } = e, a = Te(r);
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    Gr,
    {
      id: r.name,
      "data-testid": r.name,
      ...n.register(X(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: a
    }
  ) });
}, q = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = Te(a), o = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    ke,
    {
      id: a.name,
      "data-testid": a.name,
      type: o,
      placeholder: te(
        e.t,
        a.annotations?.inputTypePlaceholder,
        a.name,
        a.annotations?.inputOptionLabelsI18nPrefix
      ),
      readOnly: a.readOnly,
      isRequired: l,
      ...n.register(X(a.name))
    }
  ) });
}, Ke = {
  text: q,
  textarea: ha,
  select: ft,
  "select-radiobuttons": mt,
  multiselect: ft,
  "multiselect-checkboxes": mt,
  "html5-email": q,
  "html5-tel": q,
  "html5-url": q,
  "html5-number": q,
  "html5-range": q,
  "html5-datetime-local": q,
  "html5-date": q,
  "html5-month": q,
  "html5-time": q,
  "multi-input": ia
}, al = ({
  t: e,
  form: n,
  userProfileMetadata: r,
  supportedLocales: a,
  currentLocale: l,
  hideReadOnly: o = !1,
  renderer: i
}) => {
  const s = _(() => {
    if (!r.attributes)
      return [];
    const u = o ? r.attributes.filter(({ readOnly: c }) => !c) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((c) => ({
      group: c,
      attributes: u.filter(
        (h) => h.group === c.name
      )
    }));
  }, [
    o,
    r.groups,
    r.attributes
  ]);
  return s.length === 0 ? null : /* @__PURE__ */ t(
    na,
    {
      label: e("jumpToSection"),
      sections: s.filter((u) => u.attributes.length > 0).map(({ group: u, attributes: c }) => ({
        title: te(e, u.displayHeader, u.name) || e("general"),
        panel: /* @__PURE__ */ b("div", { className: "pf-v5-c-form", children: [
          u.displayDescription && /* @__PURE__ */ t(Pe, { className: "pf-v5-u-pb-lg", children: te(e, u.displayDescription, "") }),
          c.map((h) => /* @__PURE__ */ t(
            pa,
            {
              t: e,
              form: n,
              supportedLocales: a,
              currentLocale: l,
              renderer: i,
              attribute: h
            },
            h.name
          ))
        ] })
      }))
    }
  );
}, pa = ({
  t: e,
  form: n,
  renderer: r,
  supportedLocales: a,
  currentLocale: l,
  attribute: o
}) => {
  const i = n.watch(
    X(o.name)
  ), s = _(() => fa(o), [o]), u = o.multivalued || ya(i) && o.annotations?.inputType === void 0 ? Ke["multi-input"] : Ke[s];
  return o.name === "locale" ? /* @__PURE__ */ t(
    la,
    {
      form: n,
      supportedLocales: a,
      currentLocale: l,
      t: e,
      attribute: o
    }
  ) : /* @__PURE__ */ t(
    u,
    {
      t: e,
      form: n,
      inputType: s,
      attribute: o,
      renderer: r
    }
  );
}, ma = "text";
function fa(e) {
  if (Ht(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return ga(n) ? n : ma;
}
const ga = (e) => typeof e == "string" && e in Ke, ya = (e) => Array.isArray(e) && e.length > 1, va = ({
  className: e = "",
  border: n,
  size: r = "md"
}) => /* @__PURE__ */ b(
  "svg",
  {
    className: br(
      me.avatar,
      me.modifiers[r],
      n === "light" && me.modifiers.light,
      n === "dark" && me.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ t(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ t(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ t(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ t(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ t("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ b("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), gt = ({
  isKebab: e = !1,
  title: n,
  dropDownItems: r,
  ...a
}) => {
  const [l, o] = x(!1);
  return /* @__PURE__ */ t(
    Sn,
    {
      ...a,
      popperProps: {
        position: "right"
      },
      onOpenChange: (i) => o(i),
      toggle: (i) => /* @__PURE__ */ t(
        de,
        {
          "data-testid": `${a["data-testid"]}-toggle`,
          ref: i,
          onClick: () => o(!l),
          isExpanded: l,
          variant: e ? "plain" : "default",
          children: e ? /* @__PURE__ */ t(ur, {}) : n
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(An, { children: r })
    }
  );
};
function ba(e, n) {
  if (!e)
    return n("unknownUser");
  const r = e.given_name, a = e.family_name, l = e.preferred_username;
  return r && a ? n("fullName", { givenName: r, familyName: a }) : r || a || l || n("unknownUser");
}
const ll = ({
  keycloak: e,
  brand: { src: n, alt: r, className: a, ...l },
  avatar: o,
  features: {
    hasLogout: i = !0,
    hasManageAccount: s = !0,
    hasUsername: u = !0
  } = {},
  kebabDropdownItems: c,
  dropdownItems: h = [],
  toolbarItems: d,
  ...f
}) => {
  const { t: m } = B(), v = [];
  s && v.push(
    /* @__PURE__ */ t(
      it,
      {
        onClick: () => e.accountManagement(),
        children: m("manageAccount")
      },
      "manageAccount"
    )
  ), i && v.push(
    /* @__PURE__ */ t(it, { onClick: () => e.logout(), children: m("signOut") }, "signOut")
  );
  const T = e.idTokenParsed?.picture;
  return /* @__PURE__ */ b(En, { ...f, children: [
    /* @__PURE__ */ t(Rn, { children: /* @__PURE__ */ t(Dn, { variant: "plain", "aria-label": m("navigation"), children: /* @__PURE__ */ t(hr, {}) }) }),
    /* @__PURE__ */ t(On, { ...l, children: /* @__PURE__ */ t("img", { src: n, alt: r, className: a }) }),
    /* @__PURE__ */ t(Fn, { children: /* @__PURE__ */ t(ye, { children: /* @__PURE__ */ b(Be, { children: [
      d?.map((g, p) => /* @__PURE__ */ t(K, { align: { default: "alignRight" }, children: g }, p)),
      /* @__PURE__ */ t(
        K,
        {
          visibility: {
            default: "hidden",
            md: "visible"
          },
          children: /* @__PURE__ */ t(
            gt,
            {
              "data-testid": "options",
              dropDownItems: [...h, v],
              title: u ? ba(e.idTokenParsed, m) : void 0
            }
          )
        }
      ),
      /* @__PURE__ */ t(
        K,
        {
          align: { default: "alignLeft" },
          visibility: {
            md: "hidden"
          },
          children: /* @__PURE__ */ t(
            gt,
            {
              "data-testid": "options-kebab",
              isKebab: !0,
              dropDownItems: [
                ...c || h,
                v
              ]
            }
          )
        }
      ),
      /* @__PURE__ */ t(
        K,
        {
          variant: "overflow-menu",
          align: { default: "alignRight" },
          className: "pf-v5-u-m-0-on-lg",
          children: T || o?.src ? /* @__PURE__ */ t(Mn, { src: T, alt: m("avatar"), ...o }) : /* @__PURE__ */ t(va, { ...o })
        }
      )
    ] }) }) })
  ] });
}, Wt = Ye("ErrorBoundaryContext", void 0), jt = () => Xe(Wt);
class ol extends Wn {
  state = {};
  static getDerivedStateFromError = (n) => ({ error: n });
  showBoundary = (n) => {
    this.setState({ error: n });
  };
  render() {
    return /* @__PURE__ */ t(
      Wt.Provider,
      {
        value: { error: this.state.error, showBoundary: this.showBoundary },
        children: this.props.children
      }
    );
  }
}
const il = ({ children: e, fallback: n }) => {
  const { error: r } = jt();
  return r ? /* @__PURE__ */ t(n, { error: r }) : e;
};
function Ca(e, n, r) {
  const { showBoundary: a } = jt();
  le(() => {
    const l = new AbortController(), { signal: o } = l;
    return e().then((i) => {
      o.aborted || n(i);
    }).catch((i) => {
      console.error(i), o.aborted || a(i);
    }), () => l.abort();
  }, r);
}
const ka = ({
  message: e,
  instructions: n,
  onPrimaryAction: r,
  hasIcon: a = !0,
  isSearchVariant: l,
  primaryActionText: o,
  secondaryActions: i,
  icon: s,
  isDisabled: u = !1
}) => /* @__PURE__ */ b(Pn, { "data-testid": "empty-state", variant: "lg", children: [
  a && l ? /* @__PURE__ */ t(st, { icon: pr }) : a && /* @__PURE__ */ t(st, { icon: s || Nt }),
  /* @__PURE__ */ t(Ln, { titleText: e, headingLevel: "h1" }),
  /* @__PURE__ */ t(Nn, { children: n }),
  /* @__PURE__ */ b(Bn, { children: [
    o && /* @__PURE__ */ t(
      L,
      {
        "data-testid": `${e.replace(/\W+/g, "-").toLowerCase()}-empty-action`,
        variant: "primary",
        onClick: r,
        isDisabled: u,
        children: o
      }
    ),
    i && /* @__PURE__ */ t(Vn, { children: i.map((c) => /* @__PURE__ */ t(
      L,
      {
        "data-testid": `${c.text.replace(/\W+/g, "-").toLowerCase()}-empty-action`,
        variant: c.type || ve.secondary,
        onClick: c.onClick,
        isDisabled: u,
        children: c.text
      },
      c.text
    )) })
  ] })
] }), Ia = ({
  toolbarItem: e,
  subToolbar: n,
  toolbarItemFooter: r,
  children: a,
  searchTypeComponent: l,
  inputGroupName: o,
  inputGroupPlaceholder: i,
  inputGroupOnEnter: s
}) => {
  const { t: u } = B(), [c, h] = x(""), d = () => {
    c !== "" ? (h(c), s?.(c)) : (h(""), s?.(""));
  }, f = (m) => {
    m.key === "Enter" && d();
  };
  return /* @__PURE__ */ b(H, { children: [
    /* @__PURE__ */ t(ye, { children: /* @__PURE__ */ b(Be, { children: [
      o && /* @__PURE__ */ t(K, { children: /* @__PURE__ */ b(Ce, { "data-testid": o, children: [
        l,
        i && /* @__PURE__ */ t(
          _n,
          {
            "data-testid": "table-search-input",
            placeholder: i,
            "aria-label": u("search"),
            value: c,
            onChange: (m, v) => {
              h(v);
            },
            onSearch: d,
            onKeyDown: f,
            onClear: () => {
              h(""), s?.("");
            }
          }
        )
      ] }) }),
      e
    ] }) }),
    n && /* @__PURE__ */ t(ye, { children: /* @__PURE__ */ t(Be, { children: n }) }),
    /* @__PURE__ */ t(qn, {}),
    a,
    /* @__PURE__ */ t(ye, { children: r })
  ] });
}, yt = ({
  id: e,
  variant: n = "top",
  count: r,
  first: a,
  max: l,
  onNextClick: o,
  onPreviousClick: i,
  onPerPageSelect: s
}) => {
  const { t: u } = B(), c = Math.round(a / l);
  return /* @__PURE__ */ t(
    $n,
    {
      widgetId: e,
      titles: {
        paginationAriaLabel: `${u("pagination")} ${n} `
      },
      isCompact: !0,
      toggleTemplate: ({
        firstIndex: h,
        lastIndex: d
      }) => /* @__PURE__ */ b("b", { children: [
        h,
        " - ",
        d
      ] }),
      itemCount: r + c * l,
      page: c + 1,
      perPage: l,
      onNextClick: (h, d) => o((d - 1) * l),
      onPreviousClick: (h, d) => i((d - 1) * l),
      onPerPageSelect: (h, d, f) => s(f - 1, d),
      variant: n
    }
  );
}, wa = ({
  count: e,
  searchTypeComponent: n,
  toolbarItem: r,
  subToolbar: a,
  children: l,
  inputGroupName: o,
  inputGroupPlaceholder: i,
  inputGroupOnEnter: s,
  ...u
}) => /* @__PURE__ */ t(
  Ia,
  {
    searchTypeComponent: n,
    toolbarItem: /* @__PURE__ */ b(H, { children: [
      r,
      /* @__PURE__ */ t(K, { variant: "pagination", children: /* @__PURE__ */ t(yt, { count: e, ...u }) })
    ] }),
    subToolbar: a,
    toolbarItemFooter: e !== 0 ? /* @__PURE__ */ t(K, { variant: "pagination", children: /* @__PURE__ */ t(yt, { count: e, variant: "bottom", ...u }) }) : null,
    inputGroupName: o,
    inputGroupPlaceholder: i,
    inputGroupOnEnter: s,
    children: l
  }
), xa = () => {
  const { t: e } = B();
  return /* @__PURE__ */ t(Kn, { children: /* @__PURE__ */ t(wt, { "aria-label": e("spinnerLoading") }) });
}, Gt = (e) => !!e && e.title !== void 0, vt = ({
  row: e,
  index: n,
  actions: r,
  actionResolver: a
}) => /* @__PURE__ */ b(H, { children: [
  e.cells.map((l, o) => /* @__PURE__ */ t(ae, { children: Gt(l) ? l.title : l }, `cell-${o}`)),
  (r || a) && /* @__PURE__ */ t(ae, { isActionCell: !0, children: /* @__PURE__ */ t(
    xr,
    {
      items: r || a?.(e, {}),
      extraData: { rowIndex: n }
    }
  ) })
] }), Ta = ({ row: e }) => e.cells.map((n, r) => /* @__PURE__ */ t("div", { children: Gt(n) ? n.title : n }, `cell-${r}`));
function Sa({
  columns: e,
  rows: n,
  actions: r,
  actionResolver: a,
  ariaLabelKey: l,
  onSelect: o,
  onCollapse: i,
  canSelectAll: s,
  isNotCompact: u,
  isRadio: c,
  ...h
}) {
  const { t: d } = B(), [f, m] = x([]), [v, T] = x([]), g = (p, C) => {
    const w = [
      ...p === -1 ? Array(n.length).fill(C) : f
    ];
    w[p] = C, m(w);
  };
  return le(() => {
    if (s) {
      const p = document.getElementsByName("check-all").item(0);
      if (p) {
        const C = p, w = f.filter((k) => k === !0);
        C.indeterminate = w.length < n.length && w.length > 0;
      }
    }
  }, [f]), /* @__PURE__ */ b(
    Cr,
    {
      ...h,
      variant: u ? void 0 : kr.compact,
      "aria-label": d(l),
      children: [
        /* @__PURE__ */ t(Ir, { children: /* @__PURE__ */ b(fe, { children: [
          i && /* @__PURE__ */ t(Oe, { screenReaderText: d("expandRow") }),
          s && /* @__PURE__ */ t(
            Oe,
            {
              screenReaderText: d("selectAll"),
              select: c ? void 0 : {
                onSelect: (p, C, w) => {
                  o(C, w), g(-1, C);
                },
                isSelected: f.filter((p) => p === !0).length === n.length
              }
            }
          ),
          e.map((p) => /* @__PURE__ */ t(
            Oe,
            {
              screenReaderText: d("expandRow"),
              className: p.transforms?.[0]().className,
              children: d(p.displayKey || p.name)
            },
            p.displayKey
          ))
        ] }) }),
        i ? n.map((p, C) => /* @__PURE__ */ t(dt, { children: C % 2 === 0 ? /* @__PURE__ */ b(fe, { children: [
          /* @__PURE__ */ t(
            ae,
            {
              expand: {
                isExpanded: !!v[C],
                rowIndex: C,
                expandId: `${C}`,
                onToggle: (w, k, A) => {
                  i(A, k);
                  const V = [...v];
                  V[C] = A, T(V);
                }
              }
            }
          ),
          /* @__PURE__ */ t(
            vt,
            {
              row: p,
              index: C,
              actions: r,
              actionResolver: a
            }
          )
        ] }) : /* @__PURE__ */ b(fe, { isExpanded: !!v[C - 1], children: [
          /* @__PURE__ */ t(ae, {}),
          /* @__PURE__ */ t(ae, { colSpan: e.length, children: /* @__PURE__ */ t(wr, { children: /* @__PURE__ */ t(Ta, { row: p }) }) })
        ] }) }, C)) : /* @__PURE__ */ t(dt, { children: n.map((p, C) => /* @__PURE__ */ b(fe, { isExpanded: v[C], children: [
          o && /* @__PURE__ */ t(
            ae,
            {
              select: {
                rowIndex: C,
                onSelect: (w, k, A) => {
                  o(k, A), g(A, k);
                },
                isSelected: f[C],
                variant: c ? "radio" : "checkbox"
              }
            }
          ),
          /* @__PURE__ */ t(
            vt,
            {
              row: p,
              index: C,
              actions: r,
              actionResolver: a
            }
          )
        ] }, C)) })
      ]
    }
  );
}
function Aa({
  ariaLabelKey: e,
  searchPlaceholderKey: n,
  isPaginated: r = !1,
  onSelect: a,
  canSelectAll: l = !1,
  isNotCompact: o,
  isRadio: i,
  detailColumns: s,
  isRowDisabled: u,
  loader: c,
  columns: h,
  actions: d,
  actionResolver: f,
  searchTypeComponent: m,
  toolbarItem: v,
  subToolbar: T,
  emptyState: g,
  icon: p,
  isSearching: C = !1,
  ...w
}) {
  const { t: k } = B(), [A, V] = x([]), [y, S] = x(), [D, F] = x(), [E, M] = x(!1), [N, Se] = Vt(
    localStorage,
    "pageSize",
    10
  ), [W, zt] = x(N), [$, ie] = x(0), [j, Ae] = x(""), tt = Y(), [he, Jt] = x(0), nt = Y(), rt = () => Jt(he + 1), Qt = Ot(), at = (I, O) => I.map((R) => {
    if ("cellFormatters" in R) {
      const G = U(O, R.name);
      return R.cellFormatters?.reduce((z, P) => P(z), G);
    }
    if (R.cellRenderer) {
      const G = R.cellRenderer;
      return { title: /* @__PURE__ */ t(G, { ...O }) };
    }
    return U(O, R.name);
  }), lt = (I) => {
    const O = (R) => s?.[0]?.enabled?.(R);
    return I.map((R, G) => {
      const z = u ? u(R) : !1, P = [
        {
          data: R,
          disableSelection: z,
          disableActions: z,
          selected: !!A.find((nn) => U(nn, "id") === U(R, "id")),
          isOpen: O(R) ? !1 : void 0,
          cells: at(h, R)
        }
      ];
      return s && P.push({
        parent: G * 2,
        cells: O(R) ? at(s, R) : []
      }), P;
    }).flat();
  }, Ee = (I) => ["string", "number"].includes(typeof I) ? I.toString() : I instanceof Array ? I.map(Ee).join("") : typeof I == "object" ? Ee(
    jn(I.title) ? I.title.props : Object.values(I)
  ) : "", Re = _(
    () => j === "" || r ? void 0 : lt(D || []).filter(
      (I) => I.cells.some(
        (O) => O && Ee(O).toLowerCase().includes(j.toLowerCase())
      )
    ).slice($, $ + W + 1),
    [j, $, W]
  );
  Ca(
    async () => {
      M(!0);
      const I = tt.current === "" && j !== "";
      return I && ie(0), tt.current = j, typeof c == "function" ? he === nt.current && D ? D : await c(I ? 0 : $, W + 1, j) : c;
    },
    (I) => {
      nt.current = he, r || (F(I), I.length > $ ? I = I.slice($, $ + W + 1) : ie(0));
      const O = lt(I);
      S(O), M(!1);
    },
    [
      he,
      $,
      W,
      j,
      typeof c != "function" ? c : void 0
    ]
  );
  const Yt = () => d && yr(d).map((I, O) => (delete I.onRowClick, I.onClick = async (R, G) => {
    await d[O].onRowClick(
      (Re || y)[G].data
    ) && (r || Ae(""), rt());
  }, I)), Xt = (I, O) => {
    const R = Re || y;
    O === -1 ? S(
      R.map((P) => (P.selected = I, P))
    ) : (R[O].selected = I, S([...y]));
    const z = [
      ...vr(
        A,
        R.map((P) => P.data),
        "id"
      ),
      ...R.filter((P) => P.selected).map((P) => P.data)
    ];
    V(z), a(z);
  }, Zt = (I, O) => {
    Z[O].isOpen = I, S([...Z]);
  }, Z = Re || y, pe = !Z || Z.length === 0, De = j !== "" || C, en = s ? W * 2 : W, tn = s ? (Z?.length || 0) / 2 : Z?.length || 0;
  return /* @__PURE__ */ b(H, { children: [
    (E || !pe || De) && /* @__PURE__ */ b(
      wa,
      {
        id: Qt,
        count: tn,
        first: $,
        max: W,
        onNextClick: ie,
        onPreviousClick: ie,
        onPerPageSelect: (I, O) => {
          ie(I), zt(O), Se(O);
        },
        inputGroupName: n ? `${e}input` : void 0,
        inputGroupOnEnter: Ae,
        inputGroupPlaceholder: k(n || ""),
        searchTypeComponent: m,
        toolbarItem: /* @__PURE__ */ b(H, { children: [
          v,
          " ",
          /* @__PURE__ */ t(K, { variant: "separator" }),
          " ",
          /* @__PURE__ */ t(K, { children: /* @__PURE__ */ b(L, { variant: "link", onClick: rt, children: [
            /* @__PURE__ */ t(mr, {}),
            " ",
            k("refresh")
          ] }) })
        ] }),
        subToolbar: T,
        children: [
          !E && !pe && /* @__PURE__ */ t(
            Sa,
            {
              ...w,
              canSelectAll: l,
              onSelect: a ? Xt : void 0,
              onCollapse: s ? Zt : void 0,
              actions: Yt(),
              actionResolver: f,
              rows: Z.slice(0, en),
              columns: h,
              isNotCompact: o,
              isRadio: i,
              ariaLabelKey: e
            }
          ),
          !E && pe && De && /* @__PURE__ */ t(
            ka,
            {
              hasIcon: !0,
              icon: p,
              isSearchVariant: !0,
              message: k("noSearchResults"),
              instructions: k("noSearchResultsInstructions"),
              secondaryActions: C ? [] : [
                {
                  text: k("clearAllFilters"),
                  onClick: () => Ae(""),
                  type: ve.link
                }
              ]
            }
          ),
          E && /* @__PURE__ */ t(xa, {})
        ]
      }
    ),
    !E && pe && !De && g
  ] });
}
const Ea = ({ link: e, organization: n }) => {
  const { t: r } = B();
  return /* @__PURE__ */ t(Tr, { wrapModifier: "truncate", children: /* @__PURE__ */ b(e, { organization: n, children: [
    n.name,
    !n.enabled && /* @__PURE__ */ t(
      Un,
      {
        isRead: !0,
        className: "pf-v5-u-ml-sm",
        children: r("disabled")
      },
      `${n.id}-disabled`
    )
  ] }) });
}, Ra = (e) => {
  const { t: n } = B();
  return /* @__PURE__ */ t(
    ze,
    {
      numChips: 2,
      expandedText: n("hide"),
      collapsedText: n("showRemaining"),
      children: e.domains?.map((r) => {
        const a = typeof r == "string" ? r : r.name;
        return /* @__PURE__ */ t(Je, { isReadOnly: !0, children: a }, a);
      })
    }
  );
}, sl = ({
  loader: e,
  toolbarItem: n,
  isPaginated: r = !1,
  isSearching: a = !1,
  onSelect: l,
  onDelete: o,
  deleteLabel: i = "delete",
  link: s,
  children: u
}) => {
  const { t: c } = B();
  return /* @__PURE__ */ t(
    Aa,
    {
      loader: e,
      isPaginated: r,
      isSearching: a,
      ariaLabelKey: "organizationList",
      toolbarItem: n,
      onSelect: l,
      canSelectAll: l !== void 0,
      actions: o ? [
        {
          title: c(i),
          onRowClick: o
        }
      ] : void 0,
      columns: [
        {
          name: "name",
          displayKey: "name",
          cellRenderer: (h) => /* @__PURE__ */ t(Ea, { link: s, organization: h })
        },
        {
          name: "domains",
          displayKey: "domains",
          cellRenderer: Ra
        },
        {
          name: "description",
          displayKey: "description"
        },
        {
          name: "membershipType",
          displayKey: "membershipType"
        }
      ],
      emptyState: u
    }
  );
}, bt = "pf-v5-theme-dark", Ct = window.matchMedia("(prefers-color-scheme: dark)");
function kt(e) {
  const { classList: n } = document.documentElement;
  e ? n.add(bt) : n.remove(bt);
}
function cl() {
  kt(Ct.matches), Ct.addEventListener(
    "change",
    (e) => kt(e.matches)
  );
}
export {
  Nr as AlertProvider,
  ja as ContinueCancelModal,
  il as ErrorBoundaryFallback,
  ol as ErrorBoundaryProvider,
  Br as ErrorPage,
  qt as FormErrorText,
  Yr as FormPanel,
  Za as FormSubmitButton,
  $r as Help,
  $t as HelpItem,
  Xa as IconMapper,
  Aa as KeycloakDataTable,
  ll as KeycloakMasthead,
  Ha as KeycloakProvider,
  ua as KeycloakSelect,
  xa as KeycloakSpinner,
  Gr as KeycloakTextArea,
  ka as ListEmptyState,
  Ga as NumberControl,
  sl as OrganizationTable,
  wa as PaginatingTableToolbar,
  za as PasswordControl,
  Kt as PasswordInput,
  na as ScrollForm,
  jr as SelectControl,
  re as SelectVariant,
  Ja as SwitchControl,
  Ia as TableToolbar,
  Qa as TextAreaControl,
  Ya as TextControl,
  al as UserProfileFields,
  el as beerify,
  Ye as createNamedContext,
  tl as debeerify,
  Or as generateId,
  Er as getErrorDescription,
  Ar as getErrorMessage,
  Wa as getInjectedEnvironment,
  Rr as getNetworkErrorDescription,
  Dr as getNetworkErrorMessage,
  cl as initializeDarkMode,
  Fr as isDefined,
  rl as isUserProfileError,
  te as label,
  ta as mainPageContentId,
  nl as setUserProfileServerError,
  Ka as useAlerts,
  Ua as useEnvironment,
  jt as useErrorBoundary,
  Ca as useFetch,
  qr as useHelp,
  Xe as useRequiredContext,
  Mr as useSetTimeout,
  Vt as useStoredState
};
