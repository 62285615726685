import { useCallback, useEffect } from "react";

import {
  ActionGroup,
  AlertVariant,
  Button,
  FormGroup,
  PageSection,
  ValidatedOptions,
} from "@patternfly/react-core";

import { ViewHeader } from "../../../../components/view-header/ViewHeader";

import { FormProvider, useForm } from "react-hook-form";

import { FormAccess } from "../../../../components/form/FormAccess";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useServerInfo } from "../../../../context/server-info/ServerInfoProvider";
import { useAdminClient } from "../../../../admin-client";
import { useRealm } from "../../../../context/realm-context/RealmContext";

import { getAuthorizationHeaders } from "../../../../utils/getAuthorizationHeaders";
import { toIntegrationRoute } from "../../../routes/integrationsRoutes";
import { AdminEvents } from "../../../enums/AdminEvents";
import { TextControl, useAlerts,SelectControl, SelectVariant } from  "@keycloak/keycloak-ui-shared";


export type CreateWebhookSchema = {
  name: string;
  url: string;
  type: "plain" | "jwt";
  loginEvents: string[];
  adminEvents: string[];
};

export type FormattedWebhookSchema = {
  name: string;
  url: string;
  type: "plain" | "jwt";
  eventsListeners: string[];
  adminEventsListeners: string[];
};

export default function EditWebhook() {
  const { webhookId } = useParams();
  const form = useForm<CreateWebhookSchema>({
    defaultValues: {
      name: "",
      url: "",
      type: "plain",
      loginEvents: [],
      adminEvents: [],
    },
  });

  const { enums } = useServerInfo();
  const navigate = useNavigate();
  const { addAlert, addError } = useAlerts();
  const { adminClient } = useAdminClient();
  const eventTypeOptions = (enums?.["eventType"] ?? []).map((item) => ({
    label: item,
    value: item,
  }));

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = form;

  const { realm } = useRealm();

  const { t } = useTranslation();

  const onSubmit = async (data: CreateWebhookSchema) => {
    if (!webhookId) return;
    const realmRep = await adminClient.realms.findOne({ realm });
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/integrations`;
    try {
      const formattedData = {
        uuid: webhookId,
        realmId: realmRep?.id,
        name: data.name,
        url: data.url,
        type: data.type,
        eventsListeners: data.loginEvents,
        adminEventsListeners: data.adminEvents,
      };
      
      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(formattedData),
        headers: {
          "Content-Type": "application/json",
          ...getAuthorizationHeaders(await adminClient.getAccessToken()),
        },
      });
      navigate(toIntegrationRoute({ realm }));
      if (response.ok) {
        const successMessage = t("point-webhookEditedSuccessfuly");
        addAlert(successMessage, AlertVariant.success);
      }
    } catch (error) {
      addError(t("point-error"), error);
    }
  };

  const getIntegrations = useCallback(async () => {
    if (!webhookId) return;
    const realmRep = await adminClient.realms.findOne({ realm });
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/integrations/${webhookId}/${realmRep?.id}`;
    const ingestionsResponse = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getAuthorizationHeaders(await adminClient.getAccessToken()),
      },
    });
    const ingestionsJson = await ingestionsResponse.json();
    if (ingestionsJson) {
      const loginEvents: string[] = JSON.parse(ingestionsJson.eventsListeners);
      const adminEvents: string[] = JSON.parse(
        ingestionsJson.adminEventsListeners,
      );

      reset({
        name: ingestionsJson.name,
        url: ingestionsJson.url,
        type: ingestionsJson.type,
        loginEvents: loginEvents,
        adminEvents: adminEvents,
      });
    }
  }, [realm, reset, webhookId]);

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  return (
    <>
      <ViewHeader titleKey={t("point-editWebhook")} />

      <PageSection variant="light">
        <FormProvider {...form}>
          <FormAccess
            role="manage-identity-providers"
            isHorizontal
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextControl isDisabled id="id" value={webhookId} name={"id"} label={t("point-webhookId")} />

            <TextControl
                name="name"
                control={control}
                label={t("point-webhookName")}
                data-testid="name"
                rules={{
                  required: {
                    value: true,
                    message: t("common:required")
                  }
                }}
              />
            <TextControl
              name="url"
              control={control}
              label={t("point-notificationUrl")}
              data-testid="url"
              rules={{
                required: {
                  value: true,
                  message: t("common:required")
                }}}
              />
            <SelectControl
              label={t("point-type")}
              name="type"
              controller={{ defaultValue: control }}
              options={[
                { key: "plain", value: "JSON" },
                {
                  key: "jwt",
                  value: "JWT",
                },
              ]}
            />
            <SelectControl
              label={t("point-loginEvents")}
              name="loginEvents"
              controller={{ defaultValue: control }}
              variant={SelectVariant.typeaheadMulti}
              options={eventTypeOptions.map((item) => ({
                key: item.value,
                value: item.label,
              }))}
            />
            <SelectControl
              label={t("point-adminEvents")}
              name="adminEvents"
              controller={{ defaultValue: control }}
              variant={SelectVariant.typeaheadMulti}
              options={Object.values(AdminEvents).map((item) => ({
                key: item,
                value: item,
              }))}
            />
            <ActionGroup>
              <Button
                variant="primary"
                type="submit"
                data-testid="createProvider"
              >
                {t("common:save")}
              </Button>
              <Button
                variant="link"
                data-testid="cancel"
                component={(props) => (
                  <Link {...props} to={toIntegrationRoute({ realm })} />
                )}
              >
                {t("common:cancel")}
              </Button>
            </ActionGroup>
          </FormAccess>
        </FormProvider>
      </PageSection>
    </>
  );
}
