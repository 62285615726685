import { useTranslation } from "react-i18next";
import { LeftNav } from "../../../PageNav";

export default function ManageNav() {
  const { t } = useTranslation();
  return (
    <>
      <LeftNav title={t("point-email-themes")} path="/emailThemes" />
      <LeftNav title={t("point-integrations")} path="/integrations" />
      <LeftNav title={t("point-ldapConnections")} path="/ldap" />
    </>
  );
}