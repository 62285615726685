import {
  Button,
  ButtonVariant,
  PageSection,
  ToolbarItem,
} from "@patternfly/react-core";
import { ViewHeader } from "../../../../components/view-header/ViewHeader";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useEmailTheme from "./useEmailTheme";

import { cellWidth, IRowData, TableText } from "@patternfly/react-table";
import { emptyFormatter } from "../../../../util";
import { useConfirmDialog } from "../../../../components/confirm-dialog/ConfirmDialog";
import {Action, KeycloakDataTable } from "@keycloak/keycloak-ui-shared";
import ClientRepresentation from "js/libs/keycloak-admin-client/lib/defs/clientRepresentation";

export default function EmailThemes() {
  const { t: tPoint } = useTranslation();
  const { t: tCommon } = useTranslation("common"); // "common"
  const { deleteId, tableData, handleDeleteTheme, handleAreYouSureDelete } =
    useEmailTheme();
  const navigate = useNavigate();

  const ToolbarItems = () => {
    return (
      <ToolbarItem>
        <Button
          component={(props) => <Link {...props} to={"create-emailTheme"} />}
        >
          {tPoint("point-createEmailTheme")}
        </Button>
      </ToolbarItem>
    );
  };

  const TextCell = (value: string) => {
    return (
      <TableText wrapModifier="truncate">
        {emptyFormatter()(value) as string}
      </TableText>
    );
  };

  const [toggleDeleteDialog, DeleteConfirm] = useConfirmDialog({
    titleKey: "Are you sure?",
    messageKey: "After delete there is no way to retrieve value",
    continueButtonLabel: "common:delete",
    continueButtonVariant: ButtonVariant.danger,
    onConfirm: () => handleDeleteTheme(deleteId),
  });

  return (
    <>
      <ViewHeader titleKey={tPoint("point-email-themes")} divider />
      <PageSection variant="light" className="pf-u-mr-sm">
        <ToolbarItems />
        <DeleteConfirm />
        <KeycloakDataTable
          loader={tableData}
          ariaLabelKey="emailthemes:emailtheme"
          actionResolver={(rowData: IRowData) => {
            const actions: Action<ClientRepresentation>[] = [
              {
                title: tCommon("common:edit"),
                onClick() {
                  const id = rowData.data.uuid;
                  navigate(id);
                },
              },
              {
                title: tCommon("common:delete"),
                onClick() {
                  const uuid = rowData.data.uuid;
                  handleAreYouSureDelete(uuid);
                  toggleDeleteDialog();
                },
              },
            ];

            return actions;
          }}
          columns={[
            {
              name: "name",
              displayKey: tPoint("point-createEmailThemeName"),
              transforms: [cellWidth(20)],
              cellRenderer: (row) => TextCell(row.themeName),
            },
            {
              name: "uuid",
              displayKey: tPoint("point-createEmailThemeId"),
              transforms: [cellWidth(20)],
              cellRenderer: (row) => TextCell(row.uuid),
            },
          ]}
        />
      </PageSection>
    </>
  );
}
